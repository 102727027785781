import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useState } from 'react'
import InputCheckbox from '../inputs/InputCheckbox'
import MaleIcon from '../icons/MaleIcon'
import FeMaleIcon from '../icons/FeMaleIcon'
import BabyBoyIcon from '../icons/BabyBoyIcon'
import BabyGirlIcon from '../icons/BabyGirlIcon'
import Table from '../table/Table'
import THead from '../table/THead'
import TR from '../table/TR'
import TH from '../table/TH'
import TBody from '../table/TBody'
import TD from '../table/TD'
import NoDataFound from '../table/NoDataFound'

const ServiceTableWithSelect = ({ t, serviceList, bookingShowData, setSelectedService, dataId }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const language = localStorage.getItem('i18nextLng') || 'dk';
    useEffect(() => {
        if (bookingShowData?.service?.id) {
            setSelectedRows([bookingShowData?.service?.id]);
        }
    }, [bookingShowData?.service?.id]);

    const handleRowSelect = (rowId, data) => {
        let updatedSelectedRows;
        if (selectedRows.includes(rowId)) {
            updatedSelectedRows = selectedRows.filter(id => id !== rowId);
        } else {
            updatedSelectedRows = [...selectedRows, rowId];
        }
        setSelectedRows(updatedSelectedRows);
        const filteredServices = serviceList.filter(service => updatedSelectedRows.includes(service.id));

        setSelectedService(filteredServices);

        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter(id => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }
    };
    // const calculateDiscount = (price, discountPrice, discountType) => {
    //     // 1=per , 2=kr
    //     if (discountType == 1) {
    //         let discount = price * discountPrice / 100;
    //         return price - discount + " Dkk.";
    //     } else if (discountType == 2) {
    //         return price - discountPrice + " Dkk.";
    //     }
    //     return price + " Dkk.";
    // }

    const calculateDiscount = (price, discountPrice, discountType) => {
        // 1=per , 2=kr
        if (discountType == 1) {
            let discount = price * discountPrice / 100;
            return Math.round(price - discount) + " Dkk.";
        } else if (discountType == 2) {
            return Math.round(price - discountPrice) + " Dkk.";
        }
        return Math.round(price) + " Dkk.";
    }
    // const calculateDiscount2 = (price, discountPrice, discountType) => {
    //     // 1=per , 2=kr
    //     if (discountType == 1) {
    //         return discountPrice + " %";
    //     } else if (discountType == 2) {
    //         return price - discountPrice + " Dkk.";
    //     }
    //     return "-";
    // }

    return (
        <div className="category-table-wrapper border-[1.3px] bg-light-800 border-success-100 rounded-xl p-3 pt-0 mb-5">
            <div className="overflow-auto">
                <Table className={"text-[15px] text-blue-950 w-full border-separate border-spacing-y-1.5"}>
                    <THead>
                        <TR>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5 text-start !ps-16"}>{t("comman_text.service")}</TH>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5"}>{t("comman_text.duration")}</TH>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5"}>{t("comman_text.for")}</TH>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5 flex flex-col"}>{t("form_input_label.price")} <span className='text-[13px]'> {t("comman_text.standard_student_senio")}</span></TH>
                        </TR>
                    </THead>
                    <TBody className={""}>
                        {
                            serviceList?.length ? serviceList.map((data, index) => (
                                <>
                                    {data?.category &&
                                        <TR className={"rounded-lg mb-5 overflow-hidden"}>
                                            <TD className={"px-5 py-2 border-b-0 font-semibold leading-[20px] border  rounded-md text-success-900  bg-success-150 text-center"}
                                                colSpan={7}>{data?.category?.name ? data?.category.name : data?.category}</TD>
                                        </TR>
                                    }
                                    {
                                        <TR className={"[&_.td-item]:last:border-1 [&>td:not(:last-child)]:before:w-[1px] [&>td:not(:last-child)]:before:h-[calc(100%-8px)] [&>td:not(:last-child)]:before:bg-success-100 [&>td:not(:last-child)]:before:block [&>td:not(:last-child)]:before:absolute [&>td:not(:last-child)]:before:end-0 [&>td:not(:last-child)]:before:top-1 [&>td:not(:last-child)]:relative"} key={index}>
                                            <TD className={"px-1 py-4 md:py-3.5 xl:py-1.5 ps-2.5 border-b-0 border border-e-0 rounded-tl-md rounded-bl-md xl:min-w-[300px] flex gap-3 justify-start items-center"}>
                                                <InputCheckbox
                                                    size={"xl"}
                                                    className={"before:rounded-full peer-checked:before:!bg-success-800 peer-checked:before:!border-success-800"}
                                                    value={`data_id_${index + 1}`}
                                                    checked={selectedRows.includes(data?.id)}
                                                    onChange={() => handleRowSelect(data?.id, [data])}
                                                    disabled={dataId ? data?.id !== dataId : false}
                                                />
                                                <p className="text-blue-950 font-medium">{language == 'en' ? data?.title : data?.dk_title}</p>
                                            </TD>
                                            <TD className={"text-center border-b-0 border-y border-success-100 px-1 py-1.5 w-[100px] xl:w-[unset]"}><p className="text-blue-950 text-sm font-medium">{`${data?.duration} min.`}</p></TD>
                                            <TD className={"px-1 py-1.5 border-b-0 border-y border-success-100 w-[100px] xl:w-[unset]"}>
                                                <ul className="flex items-end text-center justify-center gap-1">
                                                    {data.for_names?.includes('Men') ? <li className="flex-shrink-0 leading-none"><MaleIcon className="w-2.5 xl:w-3 text-[#3BDFFD]" /></li> : <></>}
                                                    {data.for_names?.includes('Women')  ? <li className="flex-shrink-0 leading-none"><FeMaleIcon className="w-2.5 xl:w-3 text-[#FE7AB6]" /></li> : <></>}
                                                    {data.for_names?.includes('Boy')  ? <li className="flex-shrink-0 leading-none"><BabyBoyIcon className="w-2.5 xl:w-3 text-[#3BDFFD]" /></li> : <></>}
                                                    {data.for_names?.includes('Girl')  ? <li className="flex-shrink-0 leading-none"><BabyGirlIcon className="w-2.5 xl:w-3 text-[#FE7AB6]" /></li> : <></>}
                                                </ul>
                                            </TD>
                                            <TD className={"px-1 py-1.5 border-b-0 border-y rounded-tr-md rounded-br-md border-e border-success-100 w-[220px] xl:w-[unset]"}>
                                                <ul className="flex items-center gap-1 text-center text-blue-950 font-normal">
                                                    <li className="w-1/3 text-danger-700">{calculateDiscount(data?.price, data?.discount_price, data?.discount_type)}</li>
                                                    <li>/</li>
                                                    <li className="w-1/3">{calculateDiscount(data?.price, data?.student_price, data?.student_type)}</li>
                                                    <li>/</li>
                                                    <li className="w-1/3">{calculateDiscount(data?.price, data?.senior_price, data?.senior_type)}</li>
                                                </ul>
                                            </TD>
                                        </TR>
                                    }
                                </>
                            )) : <NoDataFound colSpan={7} className={"text-center"} />
                        }
                    </TBody>
                </Table>
            </div>
        </div>
    )
}

export default withTranslation()(ServiceTableWithSelect)
