import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { Form } from 'antd';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from '../../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../../uiComponents/icons/CloseIcon';
import LabelWrap from '../../uiComponents/inputs/LabelWrap';
import TextareaWrap from '../../uiComponents/inputs/TextareaWrap';
import { enqueueSnackbar } from 'notistack';
import { CUSTOMER_AUTH_API_URL, PROFILE_COMPLETE_URL } from '../../../api/axios';
import { useMutation, useQueryClient  } from 'react-query';
import Http from '../../../../Http';
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import TextArea from 'antd/es/input/TextArea'
import moment from 'moment';
import { authStore } from '../../../contexts/AuthProvider';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useLocation } from 'react-router-dom';
dayjs.extend(customParseFormat);


const CancelBookingReasonModal = ({ t, modalOpen, setModalOpen, item, getBookingListing, calanderList, setCurrentDate, salonListData }) => {    
    const [loading, setLoading] = useState(false)
    const salonId = authStore((state) => state?.salonDataId);
    const queryClient = useQueryClient();
    const location = useLocation();
    
    const handleModalClose = () => {
        setModalOpen(false);
    }

    let cancelFormValidation = Yup.object().shape({
        reason: Yup.string().required(t('validation_message.reason_req')),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            reason: ""
        },
        validationSchema: cancelFormValidation,
        onSubmit: async (values) => {
            if (location?.pathname === '/customer/my-booking') {
                const payload = {
                    reason: values.reason,
                    booking_id: item?.id,
                    cancelled_by: "customer",
                };
                await cancelBooking(payload);
            }
            values.booking_ids = item?.length ? item.map(item => item?.id) : [item?.id]
            await cancelBooking(values);
        },
    });

    const { mutateAsync: cancelBooking } = useMutation(
        async (data) => {
            setLoading(true)
            return await Http.post(location?.pathname === '/customer/my-booking' ? CUSTOMER_AUTH_API_URL.cancelBooking : PROFILE_COMPLETE_URL.bookingCancel, data);
        },
        {
            onSuccess: () => {
                resetForm();
                enqueueSnackbar(t('validation_message.booking_cancel'), {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                setModalOpen(false);
                queryClient.invalidateQueries('salonListData');
                getBookingListing();
                salonListData();
                calanderList(salonId, dayjs().format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD"), 'All Worker');
                setLoading(false)
                setCurrentDate(dayjs())
                getBookingListing();
            },
            onError: (error) => {
                setLoading(false)
                const errorData = error?.response?.data?.errors;
                if (errorData) {
                    Object.values(errorData).forEach((value) => {
                        enqueueSnackbar(value, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
            },
        }
    );

    const {
        errors,
        handleSubmit,
        touched,
        resetForm,
        getFieldProps,
    } = formik;

    return (
        <>
            <Transition appear show={modalOpen} as={Fragment}>
                <Dialog as="div" className={`relative z-[999]`} onClose={handleModalClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-dark-950 bg-opacity-70"
                        />
                    </Transition.Child>
                    <FormikProvider value={formik}>
                        <Form onFinish={handleSubmit} noValidate autoComplete="off">
                            <div className="fixed inset-0 overflow-y-auto" >
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95">
                                        <Dialog.Panel className="w-full max-w-[882px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                            <div className="modal-header pe-7 mb-6">
                                                <h2 className="font-semibold text-xl text-center text-blue-950">{t("cancel_booking_reason_modal.cancel_reason")}</h2>
                                                <button
                                                    type={"button"}
                                                    aria-label="button"
                                                    className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                                    onClick={handleModalClose}>
                                                    <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className='w-full mb-4'>
                                                    <LabelWrap labelClassName={"text-[15px] font-medium"} label={t("comman_text.reason_for_cancellation")} />
                                                    {/* <TextareaWrap {...getFieldProps("reason")} rows={4} placeholder="The worker is sick or didn´t show up, ..." /> */}
                                                    <Form.Item
                                                        validateStatus={
                                                            Boolean(touched.reason && errors.reason) &&
                                                            "error"
                                                        }
                                                        help={touched.reason && errors.reason}>
                                                        <TextArea className='form-control' name="reason"
                                                            {...getFieldProps("reason")} rows={4} placeholder='The worker is sick or didn´t show up, ...'>
                                                        </TextArea>
                                                    </Form.Item>
                                                </div>
                                                <div className='w-full mb-6 xl:mb-7'>
                                                    <LabelWrap labelClassName={"text-[15px] font-medium"} label={t("cancel_booking_reason_modal.message_sent_to_customer")} />
                                                    <TextareaWrap
                                                        rows={8}
                                                        value={`Hej ${item?.customer?.first_name || ""},
Din frisør ${item?.worker?.provider?.first_name || ""} har desværre meldt afbud på din booking for den '"${moment(item?.created_at).format("DD-MM-YYYY") || ""}" - Kl. "${moment(item?.start_time).format("HH:SS") || ""}".

Begrunnelse:
"Reason typed above".

Vi undskylder for ulejligheden, men det er desværre noget som kan ske. Hvis du gerne vil bestille en ny tid nemt og hurtigt, så vælg en af nedenstående muligheder.

Vi håber på at se dig igen, og indtil da, må du have en dejlig dag!

Bedste hilsner,
“Company name” & Mee Time`}
                                                        readOnly
                                                        className="form-control"
                                                    />

                                                </div>
                                                <ButtonPrimary isLoading={loading} disabled={loading} size="sm" className="w-full max-w-[440px] mx-auto mt-3">{t("button.cancel_booking")}</ButtonPrimary>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Form>
                    </FormikProvider>
                </Dialog>
            </Transition>
        </>
    )
}

export default withTranslation()(CancelBookingReasonModal)