import React from 'react'

const FilterIcon = (props) => {
    return (
        <svg {...props} width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3562 23.0718C17.2465 23.0718 18.7788 21.5394 18.7788 19.6492C18.7788 17.7589 17.2465 16.2266 15.3562 16.2266C13.4659 16.2266 11.9336 17.7589 11.9336 19.6492C11.9336 21.5394 13.4659 23.0718 15.3562 23.0718Z" stroke="currentcolor" strokeWidth="2.34783" />
            <path d="M34.8667 18.5312H19.2145C18.4941 18.5312 17.9102 19.1152 17.9102 19.8356C17.9102 20.556 18.4941 21.1399 19.2145 21.1399H34.8667C35.587 21.1399 36.171 20.556 36.171 19.8356C36.171 19.1152 35.587 18.5312 34.8667 18.5312Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.391304" />
            <path d="M11.1288 18.5312H4.60708C3.88671 18.5312 3.30273 19.1152 3.30273 19.8356C3.30273 20.556 3.88671 21.1399 4.60708 21.1399H11.1288C11.8492 21.1399 12.4332 20.556 12.4332 19.8356C12.4332 19.1152 11.8492 18.5312 11.1288 18.5312Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.391304" />
            <path d="M24.2996 13.5425C26.1898 13.5425 27.7222 12.0101 27.7222 10.1199C27.7222 8.22962 26.1898 6.69727 24.2996 6.69727C22.4093 6.69727 20.877 8.22962 20.877 10.1199C20.877 12.0101 22.4093 13.5425 24.2996 13.5425Z" stroke="currentcolor" strokeWidth="2.34783" />
            <path d="M20.2593 9.40039H4.60708C3.88671 9.40039 3.30273 9.98437 3.30273 10.7047C3.30273 11.4251 3.88671 12.0091 4.60708 12.0091H20.2593C20.9796 12.0091 21.5636 11.4251 21.5636 10.7047C21.5636 9.98437 20.9796 9.40039 20.2593 9.40039Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.391304" />
            <path d="M34.8914 9.40039H29.2392C28.4588 9.40039 27.8262 9.98437 27.8262 10.7047C27.8262 11.4251 28.4588 12.0091 29.2392 12.0091H34.8914C35.6718 12.0091 36.3044 11.4251 36.3044 10.7047C36.3044 9.98437 35.6718 9.40039 34.8914 9.40039Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.391304" />
            <path d="M24.2996 33.7905C26.1898 33.7905 27.7222 32.2582 27.7222 30.3679C27.7222 28.4777 26.1898 26.9453 24.2996 26.9453C22.4093 26.9453 20.877 28.4777 20.877 30.3679C20.877 32.2582 22.4093 33.7905 24.2996 33.7905Z" stroke="currentcolor" strokeWidth="2.34783" />
            <path d="M20.2593 28.9658H4.60708C3.88671 28.9658 3.30273 29.5498 3.30273 30.2702C3.30273 30.9905 3.88671 31.5745 4.60708 31.5745H20.2593C20.9796 31.5745 21.5636 30.9905 21.5636 30.2702C21.5636 29.5498 20.9796 28.9658 20.2593 28.9658Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.391304" />
            <path d="M34.8914 28.9658H29.2392C28.4588 28.9658 27.8262 29.5498 27.8262 30.2702C27.8262 30.9905 28.4588 31.5745 29.2392 31.5745H34.8914C35.6718 31.5745 36.3044 30.9905 36.3044 30.2702C36.3044 29.5498 35.6718 28.9658 34.8914 28.9658Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.391304" />
        </svg>
    )
}

export default FilterIcon