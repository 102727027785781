import { Disclosure } from "@headlessui/react";
import { Checkbox } from "antd";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Http from "../../../../../Http";
import { cn } from "../../../../../lib/utils";
import { CUSTOMER_AUTH_API_URL, FRONT_API_URL } from "../../../../api/axios";
import { authStore } from "../../../../contexts/AuthProvider";
import { PATH_AUTH } from "../../../../routes/path";
import SalonDetailsFilterModal from "../../../modals/SalonDetailsFilterModal";
import ButtonPrimary from "../../../uiComponents/buttons/ButtonPrimary";
import Card from "../../../uiComponents/Card";
import BottomArrowIcon from "../../../uiComponents/icons/BottomArrowIcon";
import FilterIcon from "../../../uiComponents/icons/FilterIcon";
import LeftSmallArrowIcon from "../../../uiComponents/icons/LeftSmallArrowIcon";
import RightSmallArrowIcon from "../../../uiComponents/icons/RightSmallArrowIcon";
import ThinkingIcon from "../../../uiComponents/icons/ThinkingIcon";
import InputCheckbox from "../../../uiComponents/inputs/InputCheckbox";
import ServiceAccordianList from "./ServiceAccordianList";
import ServiceSelectableCard from "./ServiceSelectableCard";
import OfferRequestModal from "../../../modals/offerRequest/OfferRequestModal";
import SvgRender from "../../../uiComponents/common/SvgRender";

function SampleNextArrow(props) {
  const { className, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={cn(
        "absolute top-1/2 -translate-y-1/2 end-1 bg-primary1 rounded-full h-7 w-7 flex items-center justify-center border-none",
        className
      )}
    >
      <RightSmallArrowIcon className="w-3.5 h-3.5 text-white" />
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={cn(
        "absolute top-1/2 z-10 -translate-y-1/2 start-1 bg-primary1 rounded-full h-7 w-7 flex items-center justify-center border-none",
        className
      )}
    >
      <LeftSmallArrowIcon className="w-3.5 h-3.5 text-white" />
    </button>
  );
}

const SalonDetailsServices = ({
  salonId,
  t,
  salonInfo,
}) => {
  const [serviceType, setServiceTypeData] = useState([]);
  const [serviceTypeChoose, setServiceTypeChoose] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [categoryChoose, setCategoryChoose] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [discountPrices, setDiscountPrices] = useState("");
  const [count, setCount] = useState({});
  const [servicesIds, setservicesIds] = useState([]);
  const [selectCategoryTpes, setCategorytype] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [categoryIds, setCategoryIds] = useState();
  const [serviceTypeIds, setserviceTypeIds] = useState([]);
  const [promotionType, setPromostionType] = useState("");
  let disscount_type_value;
  const [SalonDetailsFilterModalOpen, setSalonDetailsFilterModalOpen] = useState(false);
  const [OfferRequestModalOpen, setOfferRequestModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState([]);
  const isAuthenticated = authStore((state) => state.isAuthenticated)
  const profileType = authStore((state) => state.profileType);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (serviceTypeChoose.length > 0) {
      subCategory();
    }
  }, [serviceTypeChoose]);

  useEffect(() => {
    if (categoryIds && serviceTypeIds) {

      salonService({
        category_ids: categoryIds,
        service_type_ids: serviceTypeIds
      });
    } else {
      salonService();
    }
  }, [categoryIds, serviceTypeIds])

  const value = 0;
  // useEffect(() => {
  //   const updatedCount = {};
  //   // serviceList?.length < 1 &&
  //   serviceList?.forEach((data) => {
  //     // data?.forEach((datas) => {
  //       const discountData = calculateDiscount(data, value, discountPrices == "" ? data?.discount_type : discountPrices, data.price);
  //       updatedCount[data.id] = {
  //         count: 0,
  //         ...discountData,
  //       };
  //     // })
  //   });

  //   setCount(updatedCount);
  // }, [serviceList]);

  useEffect(() => {
    const updatedCount = {};
    serviceList?.forEach((data) => {
      const discountData = calculateDiscount(data, value, discountPrices == "" ? 1 : discountPrices, data.price);
      updatedCount[data.id] = {
        count: 0,
        ...discountData,
      };
    });

    setCount(updatedCount);
  }, [serviceList]);

  // function calculateDiscount(data, value, discount_for, originalPrice) {
  //   const test = value == 0 ? 1 : value;
  //   const price =
  //     (discount_for == 2 && data.student_type == 2) ||
  //     (discount_for == 3 && data.senior_type == 2) ||
  //     (discount_for == 1 && data.discount_type == 2);
  //   const percentage =
  //     (discount_for == 2 && data.student_type == 1) ||
  //     (discount_for == 3 && data.senior_type == 1) ||
  //     (discount_for == 1 && data.discount_type == 1);

  //   let type = "";
  //   if (discount_for == 1) {
  //     type = data.discount_type;
  //     stPrice = data.discount_price;
  //   }
  //   if (discount_for == 2) {
  //     type = data.student_type;
  //   }
  //   if (discount_for == 3) {
  //     type = data.senior_type;
  //   }
  //   let stPrice =
  //     discount_for == 1
  //       ? data.discount_price
  //       : discount_for == 2
  //         ? data.student_price
  //         : data.senior_price;
          
  //       if (!discountPrices) {
  //         stPrice = data.discount_price;
  //       }
  //       console.log("originalPrice,stPrice",originalPrice,stPrice);
  //   if (price && discount_for) {
  //     let calPrice = stPrice * test;
  //     let updatedOriginalPrice = originalPrice * test;
  //     return {
  //       price: data.price,
  //       discount: stPrice !== "0.00" ? +stPrice + " " : "",
  //       discountMinusData: (updatedOriginalPrice - calPrice).toFixed(2),
  //       originaldbPrice: updatedOriginalPrice,
  //       discount_for: discount_for,
  //       symbol: type,
  //     };
  //   }
  //   if (percentage && discount_for) {
  //     let calPrice = originalPrice * test;
  //     // console.log({discountPercentage});
      
      
  //     let discountPercentage = (calPrice * stPrice) / 100;
  //     let updatedOriginalPrice = originalPrice * test;
  //     console.log("originalPrice,calPrice,discountPercentage",originalPrice,calPrice,discountPercentage);

  //     return {
  //       price: data.price,
  //       discount: stPrice !== "0.00" ? +stPrice + "" : "",
  //       discountMinusData: (calPrice - discountPercentage).toFixed(2),
  //       originaldbPrice: updatedOriginalPrice,
  //       discount_for: discount_for,
  //       symbol: type,
  //     };
  //   }

  //   return {
  //     price: data.price,
  //   };
  // }

  function calculateDiscount(data, value, discount_for, originalPrice) {
    const test = value == 0 ? 1 : value;
    const price =
      (discount_for == 2 && data.student_type == 2) ||
      (discount_for == 3 && data.senior_type == 2) ||
      (discount_for == 1 && data.discount_type == 2);
    const percentage =
      (discount_for == 2 && data.student_type == 1) ||
      (discount_for == 3 && data.senior_type == 1) ||
      (discount_for == 1 && data.discount_type == 1);

    let type = data.discount_type;
    let stPrice = data.discount_price;
  
    if (discount_for == 2) {
      type = data.student_type;
      stPrice = data.student_price;
    } else if (discount_for == 3) {
      type = data.senior_type;
      stPrice = data.senior_price;
    }
  
    // Use normal discount price if discountPrices is not set
    if (!discountPrices || discount_for == 1) {
      stPrice = data.discount_price;
    }
    if (price && discount_for) {
      let calPrice = stPrice * test;
      let updatedOriginalPrice = originalPrice * test;
      return {
        price: data.price,
        discount: stPrice !== "0.00" ? +stPrice + " " : "",
        discountMinusData: (updatedOriginalPrice - calPrice).toFixed(2),
        originaldbPrice: updatedOriginalPrice,
        discount_for: discount_for,
        symbol: type,
      };
    }
    if (percentage && discount_for) {
      let calPrice = originalPrice * test;
      let discountPercentage = (calPrice * stPrice) / 100;
      let updatedOriginalPrice = originalPrice * test;
      return {
        price: data.price,
        discount: stPrice !== "0.00" ? +stPrice + "" : "",
        discountMinusData: (calPrice - discountPercentage).toFixed(2),
        originaldbPrice: updatedOriginalPrice,
        discount_for: discount_for,
        symbol: type,
      };
    }

    return {
      price: data.price,
    };
  }

  const handleChangePlusCounter = (data, value) => {
    setSelectedIds([...selectedIds, data.id]);
    // value = value == 0 ? -1 : value;
    const updatedCount = value + 1;
    const discountData = calculateDiscount(
      data,
      updatedCount,
      discountPrices === "" ? 1 : discountPrices,
      data.price
    );

    setCount((prevState) => ({
      ...prevState,
      [data.id]: {
        count: updatedCount,
        ...discountData,
      },
    }));
  };

  const handleChangeMinusCounter = (data, value, check) => {
    let originalPrice = data.price;
    let serviceId = data.id;

    if (check == "check") {
      const updatedCount = 0;
      const discountData = calculateDiscount(
        data,
        updatedCount,
        // discountPrices === "" ? data?.discount_type : discountPrices,
        discountPrices == "" ? 1 : discountPrices,
        originalPrice
      );

      setCount((prevState) => ({
        ...prevState,
        [serviceId]: {
          count: updatedCount,
          ...discountData,
        },
      }));
    } else {
      if (value === 1) {
        setCount((prevState) => ({
          ...prevState,
          [serviceId]: {
            count: 0,
            ...calculateDiscount(
              data,
              0,
              // discountPrices === "" ? data?.discount_type : discountPrices,
              discountPrices == "" ? 1 : discountPrices,
              originalPrice
            ),
          },
        }));
        setSelectedIds((prevSelectedIds) =>
          prevSelectedIds.filter((id) => id !== serviceId)
        );
      } else if (value > 0) {
        const updatedCount = value - 1;
        const discountData = calculateDiscount(
          data,
          updatedCount,
          // discountPrices === "" ? data?.discount_type : discountPrices,
          discountPrices == "" ? 1 : discountPrices,
          originalPrice
        );

        setCount((prevState) => ({
          ...prevState,
          [serviceId]: {
            count: updatedCount,
            ...discountData,
          },
        }));
      }
    }
  };

  // const handleCheckBoxChange = (data, value) => { (prev code)
  //   console.log("in box change");
    
  //   if (selectedIds?.includes(data.id)) {
  //     setSelectedIds(selectedIds.filter((id) => id !== data.id));
  //     handleChangeMinusCounter(data, value, "check");
  //     setCount((prevState) => {
  //       const newState = { ...prevState };
  //       delete newState[data?.id];
  //       return newState;
  //     });
  //   } else {
  //     setSelectedIds([...selectedIds, data.id]);
  //     handleChangePlusCounter(data, value);
  //   }
  // };

  const handleCheckBoxChange = (data, value) => {
    if (selectedIds?.includes(data.id)) {
      setCount((prevState) => {
        const newState = { ...prevState };
        delete newState[data?.id];
        return newState;
      });
      handleChangeMinusCounter(data, value, "check");
      setSelectedIds((prevSelectedIds) => 
        prevSelectedIds.filter((id) => id !== data.id)
      );
    } else {
      setSelectedIds([...selectedIds, data.id]);
      handleChangePlusCounter(data, value);
    }
  };

  const { mutateAsync: salonService } = useMutation(async (data) => {
    try {
      const { data: response } = await Http.post(FRONT_API_URL.servicesFilter, {
        salon_id: salonInfo?.id,
        ...data,
        promotions: disscount_type_value ?? promotionType,
      });

      const serviceData = response.data?.data;
      if (serviceData) setServiceList(serviceData);

    } catch (error) {
      enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  });

  const handleDiscount = (value) => {
    if (!SalonDetailsFilterModalOpen) {
      setCount({});
      if (discountPrices === value) {
        setDiscountPrices("");
        disscount_type_value = "";
        setPromostionType("");
      } else {
        setDiscountPrices(value);
        // disscount_type_value = value;
        // setPromostionType(value);
        disscount_type_value = [value];
        setPromostionType([value]);
      }
    }
    salonService();
  };

  const subCategory = async (id, value) => {
    return await Http.post(FRONT_API_URL.serviceCategory, {
      service_type_ids: serviceTypeChoose,
      salon_id: salonId ?? null,
    }).then((res) => {

      if (res.data?.data.length) {
        // if (value) {          
        salonService({
          service_type_ids: id,
          category_ids: "",
        });
        // }
        setCategoryChoose(" ");
        setCategoryData(res.data.data);
      } else {
        setCategoryData([]);
      }
    });
  };

  useQuery(
    ["serviceTypeData"],
    async () => {
      const { data } = await Http.get(FRONT_API_URL.serviceType, {
        params: { salon_id: salonInfo?.id || "", flag: 1 },
      });
      setServiceTypeData(data.data);
      if (data.data.length) setServiceTypeChoose([data.data[0].id]);
    },
    {
      enabled: Boolean(salonInfo?.id),
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const handleSlideChange = (index) => {
    const currentItem = serviceType[index];
    setServiceTypeChoose([currentItem?.id]);
    subCategory([currentItem?.id], true);
  };

  const shopSliderMain = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: handleSlideChange,
    dots: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleSelectChange = (e) => {
    setServiceTypeChoose([e]);
    subCategory([e], true);
  };

  const countArray = Object.entries(count).map(([id, countData]) => ({
    id,
    count: countData.count,
    discount_for: countData.discount_for,
  }));

  const { mutateAsync: bookServices } = useMutation(
    async (data) => {
      setLoading(true)
      return await Http.post(CUSTOMER_AUTH_API_URL.serviceDetails, {
        salon_id: salonInfo.id,
        services: countArray
      });
    },
    {
      onSuccess: ({ data }) => {
        navigate(`/customer/booking-process/${btoa(salonId)}`, { state: { bookingData: countArray } });
        setLoading(false)
      },
      onError: (error) => {
        setLoading(false)
        enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        // navigate(PATH_CUSTOMER.findService)
      },
    }
  );

  const handleBooking = () => {
    if (isAuthenticated && !profileType) {
      const totalCount = countArray.reduce((sum, item) => sum + item.count, 0);
      if (totalCount < 1) {
        enqueueSnackbar(t("please select atleast one service"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        return false
      }
      else if (totalCount > 1) {
        enqueueSnackbar(t("multiple selected currently not alow"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        return false
      } else {
        bookServices()
      }
    } else if (!profileType) {
      let booking = {
        salon_id: salonInfo.id,
        services: countArray
      }
      localStorage.setItem('info', JSON.stringify(booking));
      navigate(PATH_AUTH.login, {
        state: {
          salon_id: salonInfo.id,
          salonData: salonInfo
        }
      })
    }
  }

  return (
    <div>
      <Card
        className={"mb-2.5 md:mb-4 sm:px-2.5 sm:py-[6.5px] py-[6.5px] px-2.5"}
      >
        {/* -----------------------large screeen------------------- */}
        <div className="hidden md:flex items-center justify-between gap-2">
          <div className="flex items-center gap-2 lg:gap-3">
            <ButtonPrimary
              size={"sm"}
              className={"py-1 px-3 rounded-md"}
              onClick={() => setSalonDetailsFilterModalOpen(true)}
            >
              <span>
                <FilterIcon className="w-5 h-5" />
              </span>
              {t("button_label.filter")}
            </ButtonPrimary>
            <div className="price_item xl:text-[15px] py-1 leading-[20px] px-3 rounded-full font-medium text-blue-950 border-[1.5px] border-success-100 inline-flex items-start">
              <InputCheckbox
                size={"lg"}
                className={"before:rounded-full flex-row-reverse md:peer-checked:after:start-[unset] before:peer-checked:bg-success-800"}
                value={1}
                checked={discountPrices === 1}
                onChange={() => handleDiscount(1)}
                text={t("check_box_label.deals")}
              />

            </div>
            <div className="price_item xl:text-[15px] py-1 leading-[20px] px-3 rounded-full font-medium text-blue-950 border-[1.5px] border-success-100 inline-flex items-start">
              <InputCheckbox
                size={"lg"}
                value={2}
                className={"before:rounded-full flex-row-reverse md:peer-checked:after:start-[unset] before:peer-checked:bg-success-800"}
                checked={discountPrices === 2}
                onChange={() => handleDiscount(2)}
                text={t("check_box_label.student_price")}
              />
            </div>
            <div className="price_item xl:text-[15px] py-1 leading-[20px] px-3 rounded-full font-medium text-blue-950 border-[1.5px] border-success-100 inline-flex items-start">
              <InputCheckbox
                size={"lg"}
                value={3}
                className={"before:rounded-full flex-row-reverse md:peer-checked:after:start-[unset] before:peer-checked:bg-success-800"}
                checked={discountPrices === 3}
                onChange={() => handleDiscount(3)}
                text={t("check_box_label.senior_price")}
              />
            </div>
          </div>
          <div className="w-[167px]">
            <ButtonPrimary
              className={
                "rounded-full py-1 w-full text-base lg:text-base font-medium justify-end !p-0 !pe-3"
              }
              onClick={() => setOfferRequestModalOpen(true)}
            >
              {t("button_label.request_offer")}
            </ButtonPrimary>
          </div>
        </div>

        {/* -----------------------------small screen----------------- */}
        <div className="flex items-center justify-between md:hidden">
          <div className="flex items-center gap-4">
            <span className="w-[41px] h-[37px] flex items-center justify-center bg-primary1 rounded-lg">
              <ThinkingIcon className="w-8 h-8" />
            </span>
            <div className="">
              <h6 className="text-sm text-blue-950/40 leading-[1]">
                {" "}
                Forespørgsel
              </h6>
              <span className="text-sm text-blue-950/40 leading-[1]">
                (Min.1000 Dkk.)
              </span>
            </div>
          </div>
          <ButtonPrimary
            className={"!p-0 w-[41px] h-[37px]"}
            onClick={() => setSalonDetailsFilterModalOpen(true)}
          >
            <FilterIcon className="w-6 h-6" />
          </ButtonPrimary>
        </div>
      </Card>
      <div className="flex gap-4 items-stretch">
        <Card className={"col-span-3 sm:px-4 sm:py-3 p-3 w-[240px] md:block hidden"} >
          <h4 className="text-[15px] font-medium text-blue-950/80 mb-2">
            {t('comman_text.service_type_name')}
          </h4>
          <div className="">
            <ServiceAccordianList
              serviceTypeData={serviceType}
              subCategoryData={categoryData}
              setServiceTypeChoose={setServiceTypeChoose}
              serviceList={serviceList}
              onChange={handleSelectChange}
              value={serviceTypeChoose}
              setCategoryChoose={setCategoryChoose}
              categoryChoose={categoryChoose}
              SalonDetailsFilterModalOpen={SalonDetailsFilterModalOpen}
              serviceChoose={serviceTypeChoose}
              setserviceTypeIds={setserviceTypeIds}
              setCategoryIds={setCategoryIds}
              serviceTypeIds={serviceTypeIds}
            />
          </div>
        </Card>
        <Card className={"col-span-9 sm:px-4 sm:py-3 p-3 flex-grow md:block hidden"}>
          <h4 className="text-[15px] font-medium text-blue-950/80 mb-2">
            {t('comman_text.services')}
          </h4>
          <div className="border border-borderColor py-0.5 px-4 rounded-lg mb-2 text-center font-medium text-[15px] text-blue-950">
            {t('services_module.category')}
          </div>
          <div className="">
            {serviceList?.length > 0 &&
              serviceList.flatMap((data, index) =>
                // subArray.map((data) => {
                // return (
                <ServiceSelectableCard
                  id={"_id_of_data_" + data.id}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  selectedIds={selectedIds}
                  handleCheckBoxChange={handleCheckBoxChange}
                  key={data.id}
                  data={data}
                  count={count}
                  setCount={setCount}
                  handleChangePlusCounter={handleChangePlusCounter}
                  handleChangeMinusCounter={handleChangeMinusCounter}
                />
                // );
                // })
              )}
            {/* : (
              <Empty />
            )} */}
          </div>
        </Card>
      </div>
      <Card className={"md:hidden block"}>
        <div className="service-choose-slider-wrapper py-1 px-1.5 border border-borderColor mb-2 rounded-[20px]">
          <Slider
            {...shopSliderMain}
            className="slider_flex gap_4 h-full px-10 slider-with-custom-navigation"
          >
            {serviceType?.map(({ id, name, image }, index) => (
              <div className="!flex items-center justify-center gap-2" key={index}>
                <SvgRender filePath={image} iconClassName="size-[26px] flex-shrink-0" />
                <h5>{name}</h5>
              </div>
            ))}
          </Slider>
        </div>
        <div className="border border-borderColor rounded-lg grid grid-cols-4 gap-2 px-2 py-1.5 mb-2">
          <InputCheckbox
            mainClassName="justify-center"
            name="woman"
            id="woman"
            text={t("comman_text.woman")}
          />
          <InputCheckbox
            mainClassName="justify-center"
            name="men"
            id="men"
            text={t("comman_text.men")}
          />
          <InputCheckbox
            mainClassName="justify-center"
            name="girl"
            id="girl"
            text={t("comman_text.girl")}
          />
          <InputCheckbox
            mainClassName="justify-center"
            name="boy"
            id="boy"
            text={t("comman_text.boy")}
          />
        </div>
        <div className="">
          <ul className="flex flex-col">
            {categoryData?.map(({ id, name, image_name, filter_image, services }, index) => (
              <li className="w-full" key={index}>
                <Disclosure
                  as="div"
                  className="border-b border-borderColor w-full py-3 rounded-none"
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex items-center justify-center gap-3 w-full relative">
                        <h6 className="text-base text-success-900 font-medium">
                          {name}
                        </h6>
                        <span>
                          <BottomArrowIcon
                            className={`absolute top-1/2 right-0 -translate-y-1/2 w-3 h-3 text-success-900 transition-all duration-100 ${open && "rotate-180"
                              }`}
                          />
                        </span>
                      </Disclosure.Button>
                      <Disclosure.Panel className="pt-2 text-sm text-gray-500">
                        {serviceList?.length > 0 &&
                          serviceList.flatMap((data, index) =>
                            // subArray.map((data) => {
                            //   return (
                            <ServiceSelectableCard
                              // id={"_id_of_data_" + index}
                              selectedCard={selectedCard}
                              setSelectedCard={setSelectedCard}
                              selectedIds={selectedIds}
                              handleCheckBoxChange={handleCheckBoxChange}
                              key={data.id}
                              data={data}
                              count={count}
                              setCount={setCount}
                              handleChangePlusCounter={handleChangePlusCounter}
                              handleChangeMinusCounter={handleChangeMinusCounter}
                              id={id}
                            />
                            //   );
                            // })
                          )}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </li>
            ))}
          </ul>
        </div>
      </Card>

      {(profileType != 2 && profileType != 1) && (<Card className={"w-full mt-3 sm:p-3"}>
        {Object.keys(count).length ? (
          (<ButtonPrimary size={"sm"} isLoading={loading} disabled={loading} className={"w-full"} onClick={() => handleBooking(salonInfo?.id)}>
            Book
          </ButtonPrimary>)
        ) : (
          <ButtonPrimary size={"sm"} className="btn-theme w-full disabled">Book</ButtonPrimary>
        )}
      </Card>)}
      <SalonDetailsFilterModal
        modalOpen={SalonDetailsFilterModalOpen}
        setModalOpen={setSalonDetailsFilterModalOpen}
        servicesIds={servicesIds}
        salonService={salonService}
        promotionType={promotionType}
        selectCategoryTpes={selectCategoryTpes}
      />
      <OfferRequestModal
        modalOpen={OfferRequestModalOpen}
        setModalOpen={setOfferRequestModalOpen}
        serviceList={serviceList}
        servicesIds={servicesIds}
        salonService={salonService}
        promotionType={promotionType}
        salonInfo={salonInfo}
        salonId={salonId}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        selectCategoryTpes={selectCategoryTpes}
      />
    </div>
  );
};

export default withTranslation()(SalonDetailsServices);
