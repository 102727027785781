import React, { useEffect, useMemo, useRef, useState } from 'react'
import ChoosenServicesList from '../../../uiComponents/common/ChoosenServicesList'
import { withTranslation } from 'react-i18next'
import Card from '../../../uiComponents/Card'
import InputCheckbox from '../../../uiComponents/inputs/InputCheckbox'
import LabelWrap from '../../../uiComponents/inputs/LabelWrap'
import CommonSliderWrapper from '../../../uiComponents/common/CommonSliderWrapper'
import WorkerCard from '../components/WorkerCard'
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary'
import PlusLineIcon from '../../../uiComponents/icons/PlusLineIcon'
import ProviderScheduleBookingCalender from '../../../uiComponents/common/ProviderScheduleBookingCalender'
import { useMutation, useQuery } from 'react-query'
import { CUSTOMER_AUTH_API_URL } from '../../../../api/axios'
import Http from '../../../../../Http'
import { useLocation, useParams } from 'react-router-dom'
import moment from "moment";
import { enqueueSnackbar } from 'notistack'
import InputRadioBox from '../../../uiComponents/inputs/InputRadioBox'
import LocationPin3 from '../../../uiComponents/icons/LocationPin3'
import SalonChairIcon from '../../../uiComponents/icons/SalonChairIcon'
import InfoFIllIcon from '../../../uiComponents/icons/InfoFIllIcon'
import LocationPin2 from '../../../uiComponents/icons/LocationPin2'
import RowWrap from '../../../uiComponents/RowWrap'
import LocationPin1 from '../../../uiComponents/icons/LocationPin1'
import { useNavigate } from 'react-router-dom';
import InputAutocompleteWrap from '../../../uiComponents/inputs/InputAutocompleteWrap'
import CancelBookingModal from '../../../modals/CancelBookingModal'
import TextareaWrap from '../../../uiComponents/inputs/TextareaWrap'

function BookingProcess({ t, }) {
    const { salonId } = useParams();
    const sliderRef = useRef(null);
    const [workerTiming, setWorkerTiming] = useState([]);
    const [onSalone, setOnSalone] = useState(true);
    const [bookingId, setBookingId] = useState([]);
    const [currentDate, setCurrentDate] = useState(moment())
    const [endTime, setEndTime] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [highlightStartTime, setHighlightStartTime] = useState("");
    const [highlightEndTime, setHighlightEndTime] = useState("");
    const [workersId, setWorkersId] = useState("");
    const [serviceBookings, setServiceBookings] = useState("");
    const [addressBooking, setAddressBooking] = useState("");
    const [servicesData, setServicesData] = useState([]);
    const [workerPersonalInfo, setWorkerPersonalInfo] = useState("");
    const [salonWrokerData, setSalonWrokerData] = useState([]);
    const [chooseServicesId, setChooseServicesId] = useState(0);
    const [workerSelectedList, setWorkerSelectedList] = useState(1)
    const navigate = useNavigate();
    const [serviceTime, setServiceTime] = useState("");
    const [addressLat, setAddressLat] = useState('');
    const [addressLong, setAddressLong] = useState('');
    const [address, setAddress] = useState('');
    const [onTheGoChecked, setOnTheGoChecked] = useState(false)
    const [bookingData, setBookingData] = useState([]);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [servicesIdData, setServicesIdData] = useState([]);
    const [bookingIdData, setBookingIdData] = useState([]);
    const [AddressError, setAddressError] = useState("");
    const [salonLocation, setSalonLocation] = useState("");
    const [bookingStep, setBookingStep] = useState(2);
    const [hoursLeft, setHoursLeft] = useState(false)
    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState("")
    const location = useLocation();
    const bookingdata = location.state

    useEffect(() => {
        if ((bookingdata?.bookingData?.bookings && bookingdata?.bookingData?.bookings[0]?.service_location == 2) || (bookingdata?.bookingData?.bookings && bookingdata?.bookingData?.bookings[0]?.service_location == 3)) {
            setOnTheGoChecked(true)
            setOnSalone(false)
        } else if (bookingdata?.bookingData?.bookings && bookingdata?.bookingData?.bookings[0]?.service_location == 2) {
            setOnSalone(true)
        }
        setAddress(bookingdata?.bookingData?.booking_address);
        setAddressLat(bookingdata?.bookingData?.address_lat)
        setAddressLong(bookingdata?.bookingData?.address_long)
        bookingdata?.bookingData?.bookings && setNote(bookingdata?.bookingData?.bookings[0]?.note)
    }, [bookingdata])


    useEffect(() => {
        if (onTheGoChecked && bookingdata?.bookingData?.bookings && bookingdata?.bookingData?.bookings[0]?.service_location == 2 || onTheGoChecked && bookingdata?.bookingData?.bookings && bookingdata?.bookingData?.bookings[0]?.service_location == 3) {
            setAddress(bookingdata?.bookingData?.booking_address);
            setAddressLat(bookingdata?.bookingData?.address_lat)
            setAddressLong(bookingdata?.bookingData?.address_long)
        }
    }, [onTheGoChecked])

    const getServicesData = useMemo(() => ['getServicesDatas'], [])
    const { data: getServicesDatas } = useQuery(
        getServicesData,
        async () => {
            try {
                const { data } = await Http.post(CUSTOMER_AUTH_API_URL.bookingsInProcess, {
                    salon_id: atob(salonId)
                });
                const arrBookingInfo = []
                const arrServiceId = []
                const arrBookingId = []
                const arrBookingServiceId = []
                const workerId = []
                if (data?.data.length) {
                    setServicesData(data?.data);
                    setSalonLocation(data?.data[0]?.salon_location)
                    setBookingId(data?.data[0].booking_id)
                    setWorkersId(data?.data[0].worker_id)
                    setAddressBooking(data?.data[0].service_address)
                    if (!serviceBookings) {
                        if (data?.data[chooseServicesId]?.is_same_worker == 0) {
                            setServiceBookings(2)
                        } else {
                            setServiceBookings(data?.data[0]?.is_same_worker)
                        }
                    }

                    let servicesTime = data?.data[chooseServicesId]
                    setServiceTime(parseFloat(servicesTime?.duration))

                    data?.data.forEach((el) => {
                        arrServiceId.push(el?.service_id);
                        if (el.worker_id) {
                            workerId?.push(el?.worker_id);
                        }
                        arrBookingId.push(el?.booking_id);
                        arrBookingServiceId.push({ booking_id: el?.booking_id, service_id: el?.service_id });
                    });

                    setServicesIdData(arrServiceId)
                    setBookingIdData(arrBookingId)

                    if (serviceBookings == data?.data[chooseServicesId]?.is_same_worker) {
                        if (serviceBookings == 1) {
                            if (data?.data[chooseServicesId].start_time) {
                                let startTime = data?.data[0].start_time
                                let endTime = data?.data[data.data.length - 1].end_time
                                startTime = moment.utc(startTime, "YYYY-MM-DD HH:mm:ss").local().format('HH:mm');
                                endTime = moment.utc(endTime, "YYYY-MM-DD HH:mm:ss").local().format('HH:mm');

                                setHighlightStartTime(startTime)
                                setHighlightEndTime(endTime)
                            }
                        } else if (serviceBookings == 2) {
                            if (data?.data[chooseServicesId].start_time) {

                                let startTime = data?.data[chooseServicesId].start_time
                                let endTime = data?.data[chooseServicesId].end_time

                                startTime = moment.utc(startTime, "YYYY-MM-DD HH:mm:ss").local().format('HH:mm');
                                endTime = moment.utc(endTime, "YYYY-MM-DD HH:mm:ss").local().format('HH:mm');

                                setHighlightStartTime(startTime)
                                setHighlightEndTime(endTime)
                            }
                        }

                    }
                    setWorkerPersonalInfo({
                        first_name: data?.data[0].worker_first_name,
                        last_name: data?.data[0].worker_last_name
                    })

                    setTimeout(() => {
                        if ((serviceBookings || data?.data[0]?.is_same_worker || data?.data[0]?.is_same_worker == 0) && (onTheGoChecked || onSalone)) {
                            workerFilter({
                                location: onTheGoChecked ? 2 : 1,
                                service_id: serviceBookings == 1 ? arrServiceId : [data?.data[chooseServicesId]?.service_id]
                            })
                        }
                    }, 100);
                    if (serviceBookings != data?.data[chooseServicesId]?.is_same_worker) {
                        // setWorkersId("")
                        // setBookingId("")
                    } else {
                        // setWorkersId(data?.data[chooseServicesId].worker_id)
                        setBookingId(data?.data[chooseServicesId].booking_id)
                    }

                    if (data?.data[chooseServicesId]?.is_same_worker == 2) {
                        const bookData = [{
                            booking_id: data.data[chooseServicesId].booking_id,
                            service_location: data.data[chooseServicesId].service_location,
                            start_time: data.data[chooseServicesId].start_time,
                            end_time: data.data[chooseServicesId].end_time,
                            worker_id: data.data[chooseServicesId].worker_id,
                            location: data.data[chooseServicesId].location,
                            is_same_worker: data.data[chooseServicesId].is_same_worker,
                        }]
                        setBookingData(bookData)
                    } else {
                        data?.data.map((el) => {
                            arrBookingInfo.push({
                                booking_id: el.booking_id,
                                service_location: el.service_location,
                                start_time: el.start_time,
                                end_time: el.end_time,
                                worker_id: el.worker_id,
                                is_same_worker: el.is_same_worker,
                                location: el.location,
                            })
                        })
                        setBookingData(arrBookingInfo)
                    }
                    if (data?.data[chooseServicesId].end_time) {
                        setCurrentDate(moment.utc(data?.data[chooseServicesId].end_time, "YYYY-MM-DD HH:mm:ss").local());
                    } else {
                        setCurrentDate(moment())
                    }
                    if (data?.data[0].start_time && data?.data[0].end_time) {
                        let startTime = data?.data[0].start_time
                        let endTime = data?.data[0].end_time
                        startTime = moment.utc(startTime, "YYYY-MM-DD HH:mm:ss").local().format('HH:mm');
                        endTime = moment.utc(endTime, "YYYY-MM-DD HH:mm:ss").local().format('HH:mm');
                        // setFormattedDate()
                        setHighlightStartTime(startTime)
                        setHighlightEndTime(endTime)
                    }

                    // setChooseServicesId({
                    //     index: 0,
                    //     id: data?.data[0].service_id
                    // });

                }
            } catch (error) {
                console.log(error);
                // enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                //     variant: "error",
                //     anchorOrigin: { vertical: "top", horizontal: "right" },
                //     autoHideDuration: 2000,
                // });
            }
        },
        {
            enabled: !!atob(salonId),
            refetchOnWindowFocus: false,
            retry: false,
        });
    const { mutateAsync: workerFilter } = useMutation(
        async (datas) => {
            try {
                const { data } = await Http.post(CUSTOMER_AUTH_API_URL.getWorker, {
                    salon_id: atob(salonId),
                    service_id: datas.service_id,
                    location: datas.location
                })
                if (data?.data.length) {
                    let worker;
                    // if (workersId) {
                    //     worker = data.data.find(el => el.provider.id === data.data[0]?.provider_id);
                    //     // setWorkersId(data?.data[0]?.provider_id)
                    // } else {
                    worker = data.data.find(el => el.provider.id === workersId);
                    // }

                    if (worker) {
                        setWorkerPersonalInfo({
                            first_name: worker.provider.first_name,
                            last_name: worker.provider.last_name
                        });
                        // setWorkersId(worker.provider.id);
                    } else {
                        setWorkersId(data.data[0].provider_id)
                    }
                    setSalonWrokerData(data.data)
                }
                else {
                    enqueueSnackbar("No worker found for selected service", {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                    return;
                }
                if (!workersId) {
                    employeeTimings({
                        workerId: data.data[0].provider_id,
                        currentDateTime: currentDate.format('YYYY-MM-DD')
                    });
                }

                if (workersId) {
                    employeeTimings({
                        workerId: workersId,
                        currentDateTime: currentDate.format('YYYY-MM-DD')
                    });
                } else if (data?.data.length) {
                    const info = data.data[0];
                    // setWorkersId(info.provider.id);
                    setWorkerPersonalInfo({
                        first_name: info.provider.first_name,
                        last_name: info.provider.last_name
                    });
                }
                // if (workersId) {
                //     employeeTimings({
                //         workerId: workersId,
                //         currentDateTime: currentDate.format('YYYY-MM-DD')
                //     });
                // } else if (data?.data.length) {
                //     const info = data.data[0];
                //     setWorkersId(info.provider.id);
                //     setWorkerInfo(info);
                //     setWorkerPersonalInfo({
                //         first_name: info.provider.first_name,
                //         last_name: info.provider.last_name
                //     });
                // }

            } catch (error) {
                enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        }
    );


    const handelTimeSelect = (e) => {
        if (!workersId) {
            enqueueSnackbar(t('validation_message.please_choose_worker'), {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            return false
        }
        const selectedTime = moment(e, 'HH:mm');
        const currentTime = moment().format('YYYY-MM-DD'); // Current time
        if (currentDate.format('YYYY-MM-DD') == currentTime) {
            if (selectedTime.isBefore(moment())) {
                enqueueSnackbar("Selected time is earlier than the current time", {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                return false;
            }
        }

        var formattedTime = selectedTime.format('HH:mm');
        setSelectedTime(formattedTime);
    }

    const { mutateAsync: employeeTimings } = useMutation(
        async (datas) => {
            try {
                const { data } = await Http.post(CUSTOMER_AUTH_API_URL.employeeTimings, {
                    salon_id: atob(salonId),
                    service_location: onSalone ? 1 : onTheGoChecked ? 2 : 1,
                    worker_id: workersId,
                    booking_id: datas?.booking_id ? [datas.booking_id] : [bookingId],
                    service_duration: serviceTime,
                    date: datas && datas?.currentDateTime ? datas?.currentDateTime : moment().format('YYYY-MM-DD')
                },
                    {
                        headers: {
                            "tz": "+05:30"
                        }
                    })
                let arr = []

                if (data.data?.length) {
                    let arr = []
                    data.data.map((data) => {
                        const values = moment(data.value, 'HH:mm').format('HH:mm')
                        const currentDateString = currentDate.format('YYYY-MM-DD');
                        const isDisabled = moment().isAfter(moment(`${currentDateString} ${values}`, 'YYYY-MM-DD HH:mm'), 'minute');
                        if (isDisabled) {
                            data.disabled = isDisabled
                        }
                    })
                    data.data.map((timing, key) => {
                        if (!timing.disabled) {
                            arr.push(key)
                        } else {
                            if (arr.length > 0) {
                                let arrayCount = arr.length
                                let time = Math.ceil(serviceTime / 15) * 15
                                time = (time / 15) + 1
                                if (arrayCount < time) {
                                    arr.map((value) => {
                                        data.data[value].disabled = true
                                        data.data[value].color = "red"
                                    })
                                } else {
                                    let lastElement = arr.slice(-time);
                                    lastElement.map((el, k) => {
                                        if (k > 0) {
                                            data.data[el].color = "red"
                                            data.data[el].disabled = true
                                        }
                                    })
                                }
                                arr = []
                            }
                        }
                    })
                    const earlyMorningTimings = data.data.filter(timing => timing.data_hour >= 0 && timing.data_hour < 8);
                    const morningTimings = data.data.filter(timing => timing.data_hour >= 8 && timing.data_hour < 12);
                    const afternoonTimings = data.data.filter(timing => timing.data_hour >= 12 && timing.data_hour < 17);
                    const eveningTimings = data.data.filter(timing => timing.data_hour >= 17 && timing.data_hour <= 24);
                    setWorkerTiming({
                        "early_morning": earlyMorningTimings,
                        "morning": morningTimings,
                        "afternoon": afternoonTimings,
                        "evening": eveningTimings,
                    })
                }
            } catch (error) {
                enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        }
    );
    const { mutateAsync: bookingCreate } = useMutation(
        async () => {
            try {
                setLoading(true);
                const { data } = await Http.post(CUSTOMER_AUTH_API_URL.bookingStore, {
                    booking: bookingData
                })
                if (data) {
                    setBookingStep(3)
                    navigate(`/customer/payment-info/${salonId}`, {
                        state: { bookingStep: 3 }
                    });
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                setBookingStep(2)
                const errorData = error?.response?.data;
                if (errorData) {
                    enqueueSnackbar(errorData.message, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        }
    );

    const handleCheckAvability = () => {
        // if (!serviceBookings) {
        //     enqueueSnackbar("Please select service bookings", {
        //         variant: "error",
        //         anchorOrigin: { vertical: "top", horizontal: "right" },
        //         autoHideDuration: 2000,
        //     });
        //     return false
        // }
        if (salonWrokerData.length < 1) {
            enqueueSnackbar("No worker found for selected service", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            return false
        }
        if (!workersId) {
            enqueueSnackbar("Please Choose Worker", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            return false
        }
        if (onTheGoChecked) {
            if (!address || !addressLat || !addressLong) {
                setAddressError("Please enter a valid address");
                return false;
            }
        }
        if (!selectedTime && !bookingdata?.bookingData?.bookings) {
            enqueueSnackbar("Please select time slot", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            return false;
        }
        const bookingDate = bookingdata?.bookingData?.bookings && bookingdata?.bookingData?.bookings[0]?.start_time;
        if (!selectedTime &&(bookingdata?.bookingData?.bookings && moment(bookingDate).format('YYYY-MM-DD') !== moment(currentDate).format('YYYY-MM-DD'))) {
            enqueueSnackbar("Please select time slot", {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
            });
            return false;
        }
        bookingCreate();
        // bookingCreate({
        //     service_location: onSalone ? 1 : onTheGoChecked ? 2 : 1,
        //     worker_id: workersId,
        //     start_time: startTimeDb,
        //     end_time: endTimeDb,
        //     booking_id: bookingId,
        //     is_same_worker: 2,
        //     service_location: servicesLocation == 3 ? 1 : 1,
        //     salon_id: '1'
        // })
    }

    const { mutateAsync: getServicesLocation } = useMutation(
        async (datas) => {
            try {
                const { data } = await Http.post(CUSTOMER_AUTH_API_URL.locationDetails, {
                    service_id: datas.service_id,
                    location: datas.location,
                    booking_id: datas.booking_id
                });
                if (data?.data) {
                    if (data?.data.service_location) {
                        setOnSalone(true);
                    }
                }
                workerFilter({
                    location: data.data.location,
                    service_id: servicesIdData
                })
            } catch (error) {
                setSalonWrokerData([])
                enqueueSnackbar(error?.response?.data?.message, {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            }
        }
    );

    const handleChooseService = (index, data) => {
        setSalonWrokerData([])
        getServicesLocation({
            service_id: [data?.service_id],
            location: data?.location,
            booking_id: [data?.booking_id]
        });
        setChooseServicesId(index);
        setBookingId(data.booking_id)
        if (!data.worker_id) {
            setWorkersId("")
            setBookingId("");
        } else {
            setWorkersId(data.worker_id);
            setBookingId("");
            if (data.worker_id == workersId) {
                employeeTimings({
                    workerId: data.worker_id,
                    booking_id: data.booking_id
                })
            }
        }
        setSelectedTime("")
        setEndTime("")
        if (data.start_time && data.end_time) {
            const startTime = moment.utc(data.start_time, "YYYY-MM-DD HH:mm:ss").local().format('HH:mm');
            const endTime = moment.utc(data.end_time, "YYYY-MM-DD HH:mm:ss").local().format('HH:mm');
            setHighlightStartTime(startTime)
            setHighlightEndTime(endTime)
        } else {
            setHighlightStartTime("")
            setHighlightEndTime("")
        }


    };

    const handleChooseWorker = (service, index) => {
        setWorkersId(prevWorkerId => {
            if (prevWorkerId === service?.provider_id) {
                return prevWorkerId;
            } else {
                return service?.provider_id;
            }
        });
        setWorkerPersonalInfo({
            first_name: service?.provider?.first_name,
            last_name: service?.provider?.last_name
        })
        setHighlightStartTime("")
        setHighlightEndTime("")
        setSelectedTime("")
        setEndTime("")
        setCurrentDate(moment());
    }

    useEffect(() => {
        if (workersId) {
            setTimeout(() => {
                employeeTimings({
                    workerId: workersId,
                    currentDateTime: currentDate.format('YYYY-MM-DD')
                })
            }, 500);
        }
    }, [workersId, bookingId, note])

    const handleChangeAddress = (city, location) => {
        setAddress(city);
        setAddressLat(location?.lat())
        setAddressLong(location?.lng())
        setAddressError("")

        setAddressBooking({
            service_address: city,
            address_lat: location?.lat(),
            address_long: location?.lng()
        })

    }

    const handleServiceLocation = (values) => {
        setAddressError("")
        setAddressLat("")
        setAddressLong("")
        setAddress("")
        if (values == 1) {
            setOnSalone(true);
            setOnTheGoChecked(false)
        }
        else if (values == 2) {
            setOnSalone(false);
            setOnTheGoChecked(true)
        }
        getServicesLocation({
            service_id: servicesIdData,
            location: values,
            booking_id: bookingIdData
        })

        if (salonWrokerData && salonWrokerData.length) {
            setWorkersId(salonWrokerData[0]?.provider_id)
        }

        setHighlightStartTime("")
        setHighlightEndTime("")
        setSelectedTime("")
        setEndTime("")
        setCurrentDate(moment())
        setTimeout(() => {
            employeeTimings({
                workerId: workersId,
                currentDateTime: moment().format("YYYY-MM-DD")
            })
        }, 1000);
    };

    const { mutateAsync: checkAvailablity } = useMutation(
        async (datas) => {
            try {
                const { data } = await Http.post(CUSTOMER_AUTH_API_URL.checkAvailablity, { ...datas, salon_id: atob(salonId) })
                setBookingData(data.data)
                var utcDateTime = moment.utc(data.data.end_time);
                var localDateTime = utcDateTime.local();
                var formattedTime = localDateTime.format('HH:mm');
                setEndTime(formattedTime)
            } catch (error) {
                setEndTime("");
                const errorData = error?.response?.data;
                if (errorData) {
                    enqueueSnackbar(errorData.message, {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        }
    );

    useEffect(() => {
        if (selectedTime) {
            const dateTimeString = currentDate.format("YYYY-MM-DD") + " " + selectedTime;
            setEndTime("")
            setHighlightStartTime("")
            setHighlightEndTime("")
            checkAvailablity({
                services: [{ "booking_id": bookingId, "service_id": servicesIdData }],
                worker_id: workersId,
                is_same_worker: serviceBookings,
                service_location: onSalone ? 1 : onTheGoChecked ? 2 : 1,
                slot: moment(dateTimeString, "YYYY-MM-DD HH:mm").utc().format('HH:mm'),
                date: currentDate ? moment(dateTimeString, "YYYY-MM-DD HH:mm").utc().format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                worker_first_name: workerPersonalInfo?.first_name,
                worker_last_name: workerPersonalInfo?.last_name,
                service_address: address,
                location: onTheGoChecked ? addressBooking : address,
                service_duration: serviceTime,
                note: note
                // service_address: onTheGoChecked ? addressBooking : "",
                // location: address
            })
        }
    }, [selectedTime])

    const handleCancelClick = () => {
        setHoursLeft(true);
        setConfirmModalOpen(true);
    };


    return (
        <>
            <section className='pb-20'>
                <h1 className='text-center text-white text-[40px] font-700 pt-5'>{t('comman_text.booking_completion_step')} {bookingStep}/3</h1>
                <Card className={"my-4"}>
                    <RowWrap className="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 lg:gap-x-8 lg:gap-y-5" lg={3}>
                        <div className="meetime-form-group">
                            <LabelWrap labelClassName="!mb-4 !text-[15px]" label={t("comman_text.where_would_you_like_to_be_service")} suffixIcon={<InfoFIllIcon className="w-5 h-5 flex-shrink-0" />} prefixIcon={<LocationPin2 className="w-5 h-5" />} />
                            <div className="flex items-center flex-wrap !gap-4">
                                <InputCheckbox
                                    name="location_type"
                                    id={t('check_box_label.salon')}
                                    prefixIcon={<LocationPin3 className="w-5 h-5" />}
                                    text={t('check_box_label.salon')}
                                    value={1}
                                    className={"before:rounded-full  peer-checked:after:end-[6px]"}
                                    checked={onSalone}
                                    disabled={!onTheGoChecked || salonLocation === 1 || salonLocation === 2}
                                    onChange={() => handleServiceLocation(1)}
                                />
                                <InputCheckbox
                                    name="location_type"
                                    id={t('check_box_label.on_the_go')}
                                    prefixIcon={<SalonChairIcon className="w-5 h-5" />}
                                    text={t('check_box_label.on_the_go')}
                                    value={2}
                                    disabled={!onSalone || salonLocation === 2 || salonLocation === 1}
                                    className={"before:rounded-full peer-checked:after:end-[6px]"}
                                    onChange={() => handleServiceLocation(2)}
                                    checked={onTheGoChecked}
                                />
                            </div>
                        </div>

                        {onTheGoChecked ?
                            <InputAutocompleteWrap
                                size="sm"
                                // {...getFieldProps("business_name")}
                                labelIcon={<LocationPin1 className="w-5 h-5" />}
                                label={t("comman_text.address_for_service")}
                                placeholder={t('comman_text.address_for_service')}
                                name="address"
                                value={address}
                                disabled={bookingdata?.bookingData?.booking_address ? true : false}
                                errorData={AddressError}
                                errorType={AddressError}
                                handleChange={handleChangeAddress}
                            /> : ""}

                        <TextareaWrap
                            groupClassName={"col-span-1"}
                            className="rounded-lg"
                            rows={1}
                            label="Notes"
                            placeholder="Notes"
                            value={note}
                            onChange={(event) => {
                                setNote(event.target.value);
                                setHighlightStartTime("")
                                setHighlightEndTime("")
                                setSelectedTime("")
                                setEndTime("")
                                setCurrentDate(moment());
                            }}
                            labelClassName="text-blue-950 text-[15px] font-medium" />

                    </RowWrap>
                </Card>
                {
                    workerSelectedList && [...Array(workerSelectedList)].map((item, ind) => (
                        <>
                            <Card className={"my-4"}>
                                <div className='w-full'>
                                    <LabelWrap labelClassName={"font-medium mb-1"} label={t('form_input_label.choose_worker')} />
                                    {
                                        salonWrokerData.length > 0 &&
                                        <CommonSliderWrapper
                                            ref={sliderRef}
                                            defaultArrows={false}
                                            sildNoShow={true}
                                            groupClassName="sm:max-w-[calc(100%-100px)] sm:flex sm:gap-2 sm:items-center"
                                            slidesToShow={1}
                                            xsSlidesToShow={1}
                                            smSlidesToShow={2}
                                            mdSlidesToShow={2}
                                            lgSlidesToShow={3}
                                            xlSlidesToShow={4}
                                            xxlSlidesToShow={5}
                                            infinite={false}
                                            arrowBtnGroupClassName={"mt-0 gap-2 flex-row-reverse"}
                                            countClassName="min-w-[28px] text-center"
                                        >
                                            {
                                                salonWrokerData?.map((data, index) => (
                                                    <InputRadioBox
                                                        key={index}
                                                        name={`workerList`}
                                                        groupClassName={"flex-grow w-full w-full h-full p-1"}
                                                        className={`bg-light-800 rounded-lg xl:rounded-2xl items-center gap-1 before:hidden after:hidden peer-checked:after:hidden py-1 md:py-1.5 px-2 md:px-2.5 border-[1.3px] border-success-100 w-full font-medium peer-checked:border-success-950 peer-checked:bg-success-350 hover:border-success-950 hover:bg-success-350 transition-all duration-300 h-full leading-none`}
                                                        text={
                                                            <>
                                                                <div className='w-10 h-10 md:h-[55px] md:w-[55px] overflow-hidden rounded-full object-cover object-center bg-white flex-shrink-0'>
                                                                    <img src={data?.provider?.profile} className='h-full w-full object-cover' alt="" />
                                                                </div>
                                                                <span className="text-base xl:text-lg flex-grow text-center block break-words">
                                                                    {data?.provider?.first_name && data?.provider?.last_name ? data?.provider?.first_name + " " + data?.provider?.last_name : ""}
                                                                </span>
                                                            </>
                                                        }
                                                        value={`worker_id_____${index + 1}`}
                                                        checked={workersId == data?.provider_id ? true : false}
                                                        onChange={() => handleChooseWorker(data, ind)}
                                                    />
                                                ))
                                            }
                                        </CommonSliderWrapper>
                                    }
                                </div>
                                <div className='py-4'>
                                    {
                                        salonWrokerData?.length > 0 ? (
                                            salonWrokerData
                                                .filter((el) => el.provider_id === workersId)
                                                .map((el) => (
                                                    <div className="slider_item" key={el.id}>
                                                        <WorkerCard
                                                            groupClassName="border-[1.5px]"
                                                            badgeSlider={true}
                                                            workerData={el}
                                                            // handleChooseWorker={handleChooseWorker}
                                                            workersId={workersId}
                                                            bookingId={bookingId}
                                                        />
                                                    </div>
                                                ))
                                        ) : (
                                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxxl-2 mb-4">
                                                <div className="custom_border_card h-100">
                                                    <div className='d-flex justify-content-center align-items-center flex-column h-100 w-100'>
                                                        <h4 className='' style={{ color: '#dddddd', fontSize: '18px' }}>No Worker Found</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/* <WorkerCard groupClassName="border-[1.5px]" badgeSlider={true} /> */}
                                </div>
                            </Card>
                            <Card className={"flex justify-between items-center my-4"}>
                                <ChoosenServicesList servicesData={servicesData} handleChooseService={handleChooseService} chooseServicesId={chooseServicesId} label={t('form_input_label.choose_services')} />
                            </Card>
                        </>
                    ))
                }
                {servicesData?.length > 1 && servicesData?.length > workerSelectedList ? (
                    <Card className={"my-4"}>
                        <ButtonPrimary
                            onClick={() => {
                                if (servicesData.length > workerSelectedList) {
                                    setWorkerSelectedList(workerSelectedList + 1);
                                }
                            }}
                            variant={"outline"}
                            className={"w-full"}
                            icon={<PlusLineIcon className="w-5 h-5" />}
                            size="sm"
                        >
                            {t('booking_and_calender_module.worker')}
                        </ButtonPrimary>
                    </Card>
                ) : null}

                <Card className={"my-4"}>
                    <LabelWrap labelClassName={"font-medium mb-1"} label={t('form_input_label.choose_date_time')} />
                    <ProviderScheduleBookingCalender
                        workerTiming={workerTiming}
                        selectedTime={selectedTime}
                        endTime={endTime}
                        workerId={workersId}
                        currentDate={currentDate}
                        setCurrentDate={setCurrentDate}
                        highlightStartTime={highlightStartTime}
                        highlightEndTime={highlightEndTime}
                        handelTimeSelect={handelTimeSelect}
                        setHighlightStartTime={setHighlightStartTime}
                        setHighlightEndTime={setHighlightEndTime}
                        setSelectedTime={setSelectedTime}
                        setEndTime={setEndTime}
                        employeeTimings={employeeTimings}
                        setAvailabilityData={setBookingData}
                    // setFormattedDate={setFormattedDate}
                    // formattedDate={formattedDate}
                    />
                    <div className='w-full flex flex-col sm:flex-row justify-end gap-x-4 gap-y-3 mt-5'>
                        <ButtonPrimary size="sm" className="min-w-[227px] !bg-danger-950 border-danger-950 hover:border-danger-950 " onClick={handleCancelClick}>Cancle</ButtonPrimary>
                        <ButtonPrimary size="sm" className="min-w-[227px]" isLoading={loading} disabled={loading} onClick={handleCheckAvability}>Save</ButtonPrimary>
                    </div>
                </Card>
            </section>

            <CancelBookingModal modalOpen={confirmModalOpen} setModalOpen={setConfirmModalOpen} hoursLeft={hoursLeft} title={"Are you sure that you wish to cancel your booking?"} />
        </>
    )
}

export default withTranslation()(BookingProcess)