import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import Card from '../../../../uiComponents/Card'
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap'
import CheckboxList from '../../../../uiComponents/common/CheckboxList'
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary'
import PlusLineIcon from '../../../../uiComponents/icons/PlusLineIcon'
import ServiceTable from './ServiceTable'
import { PATH_PROVIDER } from '../../../../../routes/path'
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios'
import { useMutation, useQuery } from 'react-query'
import Http from '../../../../../../Http'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { authStore } from '../../../../../contexts/AuthProvider'

const Services = ({ t, stepsNextClick, profile_setup, filterData }) => {

  const updateSalonProfileStep = authStore((state) => state.updateSalonProfileStep);
  const is_profile_complete = authStore((state) => state.is_profile_complete);
  const salonProfileStep = authStore((state) => state.salonProfileStep);

  const [categoryData, setCategoryData] = useState([]);
  const [serviceType, setServiceTypeData] = useState([]);
  const [serviceChoose, setServiceChoose] = useState([]);
  const [categoryChoose, setCategoryChoose] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [serviceTotal, setServiceTotal] = useState(0);
  const [serviceTypeIds, setserviceTypeIds] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);

  const salonId = authStore((state) => state.salonDataId);
  const navigate = useNavigate()

  // const subCategory = async () => {
  //   return await Http.post(PROFILE_COMPLETE_URL.categories, {
  //     service_type_ids: serviceTypeIds,
  //     salonId: salonId ?? null
  //   }).then((res) => setCategoryData(res.data.data));
  // };

  // useEffect(() => {
  //   subCategory()
  // }, [serviceTypeIds])
  const getsubcategory = useMemo(() => ["subCategory", serviceTypeIds], [serviceTypeIds])
  const { data: category, refetch: subCategory } = useQuery(
    getsubcategory,
    async () => {
      const response = await Http.post(PROFILE_COMPLETE_URL.categories, {
        service_type_ids: serviceTypeIds,
        salonId: salonId ?? null,
      });
      return response?.data?.data;
    },
    {
      enabled: !!salonId, 
      onSuccess: (data) => {
        setCategoryData(data); 
      },
      refetchOnWindowFocus: false, 
    }
  );

  const serviceTypeData = useMemo(() => ["serviceTypeData"], []);
  useQuery(
    serviceTypeData,
    async () => {
      try {
        const { data } = await Http.get(PROFILE_COMPLETE_URL.serviceTypes).then(
          (res) => res.data
        );

        if (data.length) {
          setServiceTypeData(data);
          subCategory([data[0].id]);
        }
      } catch (error) {
        enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      }
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  // const { mutateAsync: salonService } = useMutation(
  //   async (datas) => {
  //     try {
  //       console.log(1111,datas);

  //       if (profile_setup === true) {
  //         return await Http.post(PROFILE_COMPLETE_URL.servicesFilter, {
  //           salon_id: salonId,
  //           service_type_ids: serviceTypeIds,
  //           category_ids: categoryIds,
  //           promotions: filterData?.promotions
  //         }).then((res) => res?.data);
  //       } else {
  //         datas = { ...filterData, service_type_ids: serviceChoose, category_ids: categoryChoose, salon_id: salonId }
  //         return await Http.post(PROFILE_COMPLETE_URL.servicesFilterModal, datas).then((res) => res?.data)
  //       }
  //     } catch (error) {
  //       enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
  //         variant: "error",
  //         anchorOrigin: { vertical: "top", horizontal: "right" },
  //         autoHideDuration: 2000,
  //       });
  //     }
  //   },
  //   {
  //     onSuccess: async (res) => {
  //       // setLoadingScreen(false)
  //       setServiceTotal(res?.data.total);
  //       setServiceList(res?.data.data);

  //     }
  //   }
  // );

  const { data: salonServiceData, refetch: salonService } = useQuery(
    ["salonService", profile_setup, salonId, serviceTypeIds, categoryIds, filterData, serviceChoose, categoryChoose],
    async () => {
      try {
        if (profile_setup === true) {
          const response = await Http.post(PROFILE_COMPLETE_URL.servicesFilter, {
            salon_id: salonId,
            service_type_ids: serviceTypeIds,
            category_ids: categoryIds,
            promotions: filterData?.promotions,
          });
          const responseData = response?.data;

          if (responseData?.success) {
            const flattenedData = responseData.data.data.flatMap(group => group);

            const serviceList = {
              data: {
                data: flattenedData,
              },
            };
            return serviceList;
          }
        } else {
          const datas = {
            ...filterData,
            service_type_ids: serviceTypeIds,
            category_ids: categoryIds,
            salon_id: salonId,
          };
          const response = await Http.post(PROFILE_COMPLETE_URL.servicesFilterModal, datas);
          return response?.data;
        }
      } catch (error) {
        enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      }
    },
    {
      onSuccess: (res) => {
        setServiceTotal(res?.data?.total);
        setServiceList(res?.data?.data);
      },
      refetchOnWindowFocus: false, 
    }
  );

  const handleAddServices = () => {
    navigate(PATH_PROVIDER.addService, { state: { salonId: salonId, profile_setup: profile_setup } })
  }

  const { mutateAsync: serviceStepUpdate } = useMutation(
    async () => {
      try {
        return await Http.post(PROFILE_COMPLETE_URL.serviceStepUpdates, {
          salon_id: salonId
        }).then(
          (res) => res.data
        );

      } catch (error) {
        enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      }
    },
    {
      onSuccess: async ({ data }) => {
        if (is_profile_complete == 0 && salonProfileStep == 2 && profile_setup == true) {
          updateSalonProfileStep(3);
          stepsNextClick();
        }
      }
    }
  );

  const stepClick = () => {
    if (is_profile_complete == 0 && salonProfileStep == 2 && profile_setup == true) {
      serviceStepUpdate()
    } else {
      stepsNextClick();
    }
  };

  // useEffect(() => {
  //   if (salonId) {
  //     salonService();
  //   }
  // }, [salonId]);

  // useEffect(() => {

  //   if (filterData && filterData.promotions.length) {
  //     salonService();
  //   }
  // }, [filterData]);

  // useEffect(() => {
  //   // if (serviceTypeIds?.length && categoryIds?.length) {
  //   salonService({
  //     service_type_ids: serviceChoose,
  //     category_ids: categoryChoose,
  //   });
  //   // }
  // }, [categoryIds, serviceTypeIds]);
  return (
    <Card>
      <div className="mb-4">
        <LabelWrap labelClassName={"font-medium mb-2"} label={t("comman_text.service_type_name")}></LabelWrap>
        <CheckboxList selectAllId="one" id="service_types"
          subCategoryData={serviceType}
          serviceChoose={serviceChoose}
          setCategoryChoose={setCategoryChoose}
          categoryChoose={serviceChoose}
          setserviceTypeIds={setServiceChoose}
          setCategoryIds={setserviceTypeIds}
        />
      </div>
      <div className="mb-4">
        <LabelWrap labelClassName={"font-medium mb-2"} label={t('form_input_label.categories')}></LabelWrap>
        <div className="flex items-start justify-between flex-wrap md:flex-nowrap gap-2">
          <CheckboxList
            selectAllId="two"
            category={categoryData}
            subCategoryData={categoryData}
            serviceChoose={serviceChoose}
            setCategoryChoose={setCategoryChoose}
            categoryChoose={categoryChoose}
            setserviceTypeIds={setCategoryChoose}
            setCategoryIds={setCategoryIds}
          />
          <ButtonPrimary className={"flex-shrink-0 lg:text-base text-base font-bold rounded-[5px] py-1 px-3"} onClick={handleAddServices} size={"sm"} icon={<PlusLineIcon className="w-5 h-5" />} >{t("check_box_label.service")}</ButtonPrimary>
        </div>
      </div>
      <ServiceTable serviceList={serviceList} salonService={salonService} serviceChoose={serviceChoose} categoryChoose={categoryChoose} profile_setup={profile_setup} />
      <div className="flex items-center justify-end sm:flex-row flex-col gap-2.5">
        {/* <ButtonPrimary size={"sm"} className={"w-full sm:w-[200px]"} variant={"outline"}>Skip</ButtonPrimary> */}

        {serviceTotal != 0 && (profile_setup) ? (
          <ButtonPrimary size={"sm"} className={"w-full sm:w-[200px]"} onClick={() => { stepClick(); }} type="button">Continue</ButtonPrimary>
        ) : (
          <></>
        )}

      </div>
    </Card>
  )
}

export default withTranslation()(Services)