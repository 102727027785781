import React from 'react';
import { cn } from '../../../../lib/utils';
import HappyIcon from '../icons/HappyIcon';
import Rating from './Rating';
import RatingList from './RatingList';
import MaleIconNew from '../icons/MaleIconNew';
import MaleIcon from '../icons/MaleIcon';
import FeMaleIcon from '../icons/FeMaleIcon';
import ChildNewIcon from '../icons/ChildNewIcon';

const ReviewCard = ({ rating, groupClassName, dataList, headerGroupClass, headerLeftPartClass, headerRightPartClass, genderIconClass, headerLeftTextClassName, headerReviewTextClass, ratingWrapperClass, ratingStartIconClass, ratingGroupClass, reviewBodyClassName, reviewBodyTextMainClass, reviewBodyTextClass, reviewMoodIconClass, reviewMoodMainClass, reviewMoodTextClass, reviewMoodTextMainClass, data, ...props }) => {    
    return (
        <div className={cn('border-[1.3px] border-success-100 rounded-[10px] h-full p-2.5', groupClassName)}>
            <div className={cn("header border-b-[1.3px] border-success-100 pb-1 flex items-center justify-between", headerGroupClass)}>
                <div className={cn("flex gap-1", headerLeftPartClass)}>
                    {data?.service_for?.includes('1') && (
                        <MaleIcon className="text-[#3BDFFD] h-4 w-4" />
                    )}
                    {!data?.service_for?.includes('1') && data?.service_for?.includes('2') && (
                        <FeMaleIcon className="text-[#FE7AB6] h-4 w-4" />
                    )}
                    {!data?.service_for?.includes('1') && !data?.service_for?.includes('2') && data?.service_for?.includes('3') && (
                        <ChildNewIcon className="text-[#3BDFFD] h-3.5 w-3.5" />
                    )}
                    {!data?.service_for?.includes('1') && !data?.service_for?.includes('2') && !data?.service_for?.includes('3') && data?.service_for?.includes('4') && (
                        <ChildNewIcon className="text-[#FE7AB6] h-3.5 w-3.5" />
                    )}
                    <p className={cn('text-blue-950 text-sm', headerLeftTextClassName)}>19.02.23</p>
                </div>

                <div className={cn("flex items-center gap-1", headerRightPartClass)}>
                    <p className={cn('text-blue-950', headerReviewTextClass)}>4.5</p>
                    <Rating selected={3} wrapperClass={cn('border-0 p-0', ratingWrapperClass)} starClass={cn('h-4 w-4', ratingStartIconClass)} groupClass={cn('-mt-1.5', ratingGroupClass)} />
                </div>
            </div>
            <div className={cn("body pb-2.5", reviewBodyClassName)}>
                <div className={cn("min-h-[105px] flex items-center justify-center", reviewBodyTextMainClass)}>
                    <p className={cn('text-blue-950 text-sm text-center line-clamp-4', reviewBodyTextClass)}> Best service I ever got, I was serviced Best service I ever got, I was serviced by Christina, and she was perfect. I got exactly what I wanted! I will definitely be back for a second visit i...</p>
                </div>
                <div className={cn("flex gap-2 items-center justify-center", reviewMoodMainClass)}>
                    <span><HappyIcon className={cn('h-[30px] w-[30px]', reviewMoodIconClass)} /></span>
                    <div className={cn("", reviewMoodTextMainClass)}>
                        <p className={cn('bg-primary1 text-white text-center py-[2px] px-1.5 rounded-full text-sm mb-1', reviewMoodTextClass)}>They spoiled me</p>
                        <p className={cn('bg-primary1 text-white text-center py-[2px] px-1.5 rounded-full text-sm', reviewMoodTextClass)}>Great advice & result</p>
                    </div>
                </div>
            </div>
            {
                rating && <RatingList dataList={dataList} groupClassName={cn("border-0 border-t rounded-none grid-cols-2")} />
            }
        </div>
    );
}

export default ReviewCard;
