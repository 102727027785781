import React, { useState } from 'react'
import Card from '../../../../uiComponents/Card'
import Badge from '../../../../uiComponents/common/Badge'
import { withTranslation } from 'react-i18next';
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import DropZone from '../../../../uiComponents/inputs/DropZone';
import DragandDropIcon from '../../../../uiComponents/icons/DragandDropIcon';
import CustomTabs from '../../../../uiComponents/tabs/CustomTabs';
import CloseIcon from '../../../../uiComponents/icons/CloseIcon';
import RowWrap from '../../../../uiComponents/RowWrap';
import ChooseBookingCard from '../../../../uiComponents/common/ChooseBookingCard';
import AddPortfolioModal from '../../../../modals/bookingModals/AddPortfolioModal';


const ProtfolioThroughMeeTime = ({ t, ...props }) => {
    // const [selectedbooking, setSelectedbooking] = useState([...Array(6)])
    const [selectedbooking, setSelectedbooking] = useState([])
    const [addPortfolioModal, setAddPortfolioModal] = useState(false)

    return (
        <Card>
            <Badge text={t('portfolio.details_about_work')} />

            <LabelWrap labelClassName="mt-3" label={t("comman_text.choose_concerned_booking")} />

            {
                selectedbooking.length === 0
                    ?
                    <div className="flex items-start justify-start">
                        <ButtonPrimary onClick={() => setAddPortfolioModal(true)} size={"sm"} className={"lg:text-[15px] py-1 w-full  max-w-[200px] text-[15px] font-bold rounded-lg"} type='button' >Choose Booking</ButtonPrimary>
                    </div>
                    :
                    <div className='w-full'>

                        <div className='flex flex-col-reverse lg:flex-row gap-2 lg:gap-5 xxl:gap-10 items-start justify-start mt-3'>
                            <div className='w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-3'>
                                {
                                    [...Array(3)].map((item, index) => (

                                        <ChooseBookingCard id={index} />
                                    ))
                                }
                            </div>
                            <div className="flex items-end justify-end w-full lg:w-auto flex-shrink-0">
                                <ButtonPrimary size={"sm"} className={"w-full max-w-[150px] rounded-lg !text-sm"} type='button' onClick={() => setAddPortfolioModal(true)} >Change Booking</ButtonPrimary>
                            </div>
                        </div>
                        <RowWrap className="flex flex-col md:flex-row gap-x-5 gap-y-0 mt-5 justify-start items-start" >
                            <DropZone labelClassName="p-2 min-h-[164px] w-[300px] bg-light-950 py-5"
                                heading={<LabelWrap
                                    labelClassName="!mb-2"
                                    label={<>Upload pictures <span className="text-black/40"> (Max 4)</span></>}
                                />}
                                labelTitleClass="mt-1.5 inline" preBr
                                icon={<DragandDropIcon className="w-10 h-10 text-primary1" />}
                            />
                            <div className='grid grid-cols-2 sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 gap-4 min-h-[164px] mt-7'>

                                <CustomTabs tabGroupClass="flex flex-col-reverse" tabListClass="border-[1.3px] border-primary1 mb-0 mt-1.5" tabButtonClass="!py-0 !text-sm !font-500" tabsData={[
                                    {
                                        id: "1",
                                        title: "After",
                                        content: <div className='w-full max-h-[136px] max-w-[156px] rounded-lg overflow-hidden relative'>
                                            <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center'><CloseIcon className="w-4 h-4 text-white" /></button>
                                            <img src="../../../../../../assets/images/user1.jpg" className='h-full w-full object-cover' alt="" />
                                        </div>
                                    },
                                    {
                                        id: "2",
                                        title: "Before",
                                        content: <div className='w-full max-h-[136px] max-w-[156px] rounded-lg overflow-hidden relative'>
                                            <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center'><CloseIcon className="w-4 h-4 text-white" /></button>
                                            <img src="../../../../../../assets/images/user1.jpg" className='h-full w-full object-cover' alt="" />
                                        </div>
                                    }
                                ]} />
                                <CustomTabs tabGroupClass="flex flex-col-reverse" tabListClass="border-[1.3px] border-primary1 mb-0 mt-1.5" tabButtonClass="!py-0 !text-sm !font-500" tabsData={[
                                    {
                                        id: "1",
                                        title: "After",
                                        content: <div className='w-full max-h-[136px] max-w-[156px] rounded-lg overflow-hidden relative'>
                                            <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center'><CloseIcon className="w-4 h-4 text-white" /></button>
                                            <img src="../../../../../../assets/images/user1.jpg" className='h-full w-full object-cover' alt="" />
                                        </div>
                                    },
                                    {
                                        id: "2",
                                        title: "Before",
                                        content: <div className='w-full max-h-[136px] max-w-[156px] rounded-lg overflow-hidden relative'>
                                            <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center'><CloseIcon className="w-4 h-4 text-white" /></button>
                                            <img src="../../../../../../assets/images/user1.jpg" className='h-full w-full object-cover' alt="" />
                                        </div>
                                    }
                                ]} />

                            </div>
                        </RowWrap>

                        <div className='w-full flex justify-end items-end mt-6'>
                            <ButtonPrimary size={"sm"} className={"w-full max-w-[250px] font-bold rounded-lg"} type='button' >{t('button_label.continue')}</ButtonPrimary>
                        </div>
                    </div>
            }




            <AddPortfolioModal
                modalOpen={addPortfolioModal}
                setModalOpen={setAddPortfolioModal}
            />
        </Card>
    )
}

export default withTranslation()(ProtfolioThroughMeeTime)