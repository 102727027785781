import React from 'react'

const SearchIcon = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.5836 16.9801C32.5836 25.236 25.7548 31.9603 17.2918 31.9603C8.82873 31.9603 2 25.236 2 16.9801C2 8.72428 8.82873 2 17.2918 2C25.7548 2 32.5836 8.72428 32.5836 16.9801Z" stroke="currentcolor" strokeWidth="4" />
            <path d="M37.9989 38L29.0832 28.162" stroke="currentcolor" strokeWidth="4" strokeLinecap="round" />
        </svg>
    )
}

export default SearchIcon